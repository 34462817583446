import Vuex, { createLogger } from "vuex";
import Vue from "vue";
import * as getters from "./getters";
import * as actions from "./actions";
import * as mutations from "./mutations";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

Vue.use(Vuex);

const state = {
  startDay: dayjs().isoWeekday(1).format("YYYY-MM-DD"),
  endDay: dayjs().isoWeekday(7).format("YYYY-MM-DD"),
  country: null,
  team: null,
  totalUsers: 0,
  totalCountries: 0,
  stridesUsers: 0,
  stridesCountries: 0,
};

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: process.env.NODE_ENV !== "production" ? [createLogger()] : [],
});
